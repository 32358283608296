.vaccineBlock{
  align-items: center;
  border-style: solid;
  border-color: rgb(203, 213, 224);
  border-width: 1px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.vaccineCallToAction{
  padding-top: 1em;
  font-size: 1.2em;
  text-align: center;
}

.vaccineInfoRow{
  align-items: center;
  justify-content: center;
}

.vaccineSource{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .vaccineSourceImage{
    height: auto;
    width: 7em;
    padding: 1em;
    border-radius: 1.2em;

  }

  .vaccineSourceImage:hover{
    opacity: 20%;
  }


  /* Vaccine Stats */
  .vaccineStatTitle{
    padding: 0.1em;
    color: gray;
    font-size: 1em;
    width: 10em;
    text-align: center;
  }

  .vaccineStatValue{
    font-weight: bold;
    color: rgb(93,232,196);
    font-size: 2em;
    text-align: center;
  }

  /* Vaccine Graph */

  .vaccination-graph{
    height: 20em;
    width:40vw;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    padding:0.1em
  }

  .vaccination-graph-title{
    padding-top: 1em;
  }

  /* Herd Immunity Bar */
.herd-immunity-block{
  height: 10em;
  display: flex;
  flex-direction: column;
  width: 30vw;
}

.herd-immunity-title{
  font-size: 1.2em;

}

  .herd-immunity-bar{
    width: 12em;
    height: 3em;
    border:gray 0.2em solid;
    border-radius: 1vw;
  }

  .progress{
    height: 100%;
    font-size: 1.2em;
  }

  .current-herd-progress{
    height: 8vh;
    left:0;
    background-color: greenyellow;
    border-radius: 1vw;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .herd-immunity-marker{
    position: relative;
    top:-2.6em;
    left:70%;
    width: 0.1vw;
    height: 2.6em;
    border-left:#2ecc71 0.5em solid;

  }

  @media (max-width: 767px) {
    .herd-immunity-title{
      font-size: 1em;
    }

    .herd-immunity-block{
      width:90vw;
    }

    .vaccination-graph{
      width: 90vw;

    }
  
  }
