@import url(https://fonts.googleapis.com/css2?family=Open+Sans&display=swap);
:root{
  --BLOCK_WIDTH:16vw;
  --DATA_LABEL_BACKGROUND_COLOR: rgb(0, 123, 255);
  --DATA_VALUE_TEXT_COLOR: white;
  --DATA_VALUE_BACKGROUND_COLOR: #ecf0f1;
  --LABEL_BLOCK_HEIGHT: 12vh;
  --DATA_BLOCK_HEIGHT: 11vh;

  --LABEL_FONT_SIZE: 3vh;
  --DATA_FONT_SIZE: 3.2vh;

}

html{
  width:100%;
  height: 100%;
}
body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width:100%;
  height: 100%;

}
#root{
  width:100%;
  height: 100%;
}

.appContainer{
  width:100%;
  height: 100%;
}

.fullSpaceContainer{
  display: flex;
  flex-direction: column;
  width:100%;
  height: 100%;  
}
.flexRow{
  display: flex;
  flex-direction: row;
}

.flexColumn{
  display: flex;
  flex-direction: column;
}

.centeringContainer{
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
}

/* Hackathon Page */

.typingTitle{
  font-size: 80px;
}

.typingAnimationFirst { 
  border-right: solid 3px rgba(0,255,0,.75);
  white-space: nowrap;
  overflow: hidden;    
  font-family: 'Source Code Pro', monospace;  
  color: rgba(255,255,255,.70);
}

.typingAnimationFirst {
  -webkit-animation: animated-text 4s steps(29,end) 1s 1 normal both,
             animated-cursor 600ms steps(29,end) infinite;
          animation: animated-text 4s steps(29,end) 1s 1 normal both,
             animated-cursor 600ms steps(29,end) infinite;
}

.typingAnimationSecond {
  -webkit-animation-delay: 2s;
          animation-delay: 2s;

  -webkit-animation: animated-text 4s steps(29,end) 1s 1 normal both,
             animated-cursor 600ms steps(29,end) infinite;

          animation: animated-text 4s steps(29,end) 1s 1 normal both,
             animated-cursor 600ms steps(29,end) infinite;
             
}


/* text animation */

@-webkit-keyframes animated-text{
  from{width: 0%;}
  to{width: 100%;}
}

@keyframes animated-text{
  from{width: 0%;}
  to{width: 100%;}
}

/* cursor animations */

@-webkit-keyframes animated-cursor{
  from{border-right-color: rgba(0,255,0,.75);}
  to{border-right-color: transparent;}
}

@keyframes animated-cursor{
  from{border-right-color: rgba(0,255,0,.75);}
  to{border-right-color: transparent;}
}


/* Appear animation */

.fadeInSlow{
  -webkit-animation: fadeInAnimation ease 10s;
          animation: fadeInAnimation ease 10s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1; 
  -webkit-animation-fill-mode: forwards; 
          animation-fill-mode: forwards; 

}

.fadeInFast{
  -webkit-animation: fadeInAnimation ease 1s;
          animation: fadeInAnimation ease 1s;
  -webkit-animation-iteration-count: 1;
          animation-iteration-count: 1; 
  -webkit-animation-fill-mode: forwards; 
          animation-fill-mode: forwards; 

}

@-webkit-keyframes fadeInAnimation {
  0% {opacity:0;}
  66% { opacity:0; }
  100% {opacity: 1;}
}

@keyframes fadeInAnimation {
  0% {opacity:0;}
  66% { opacity:0; }
  100% {opacity: 1;}
} 




.gradientBackground{
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,1) 66%, rgba(43,238,166,1) 89%, rgba(0,212,255,1) 100%);  
  }

.coolGradient{
  background: rgb(34,193,195);
  background: linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);  
}

.clickable{
  cursor: pointer;
}

.clickable:hover {
  text-decoration: underline;
}

.videoContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;


}

.maintenanceMessageContainer{
  display: flex;
  flex-direction: row;
  align-items:  center;
  height: 100%;
  width: 100%
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.statsRow{
  display: flex;
  flex-direction: row;
  align-items: center;

}

.statsBlock{
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  text-align: center;
  margin: 5px;
  border-style:solid;
  border-width: 1px;
  border-radius: 15px;
  border-color: #cbd5e0;
  display: flex;
  flex-direction: row;
  width: 480px;
  justify-content: space-evenly
}


.attributeToGraphSelection {
  flex-direction:row;
  display:flex;
  padding:10;
  width: 100vw;
  align-items:center;
  justify-content: center;
  margin-top: 2vh;
}

.dashboardBlock {
  width: 16vw;
  width: var(--BLOCK_WIDTH);
  min-width: 85px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:center;
  text-align: center;
}



.labelBlock{
  background-color: rgb(0, 123, 255);
  background-color: var(--DATA_LABEL_BACKGROUND_COLOR);
  font-size: 3vh;
  font-size: var(--LABEL_FONT_SIZE);
  height: 12vh;
  height: var(--LABEL_BLOCK_HEIGHT);
  color: white;
  color: var(--DATA_VALUE_TEXT_COLOR);
}

.infoCircle{
  display: none;
  position: absolute;
  top:0;
  right:0;
  -webkit-transform:scale(0.7);
          transform:scale(0.7);
  cursor: pointer;

}

@-webkit-keyframes infoCircleHoverAnimation {
  from {fill: white; -webkit-transform: scale(0.7); transform: scale(0.7);}
  to {fill:black;-webkit-transform: scale(1.1);transform: scale(1.1);}
}

@keyframes infoCircleHoverAnimation {
  from {fill: white; -webkit-transform: scale(0.7); transform: scale(0.7);}
  to {fill:black;-webkit-transform: scale(1.1);transform: scale(1.1);}
}

.infoCircle:hover{
  -webkit-animation-name: infoCircleHoverAnimation;
          animation-name: infoCircleHoverAnimation;
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
          animation-fill-mode: forwards;

}

.dataBlock{
  background-color: #ecf0f1;
  background-color: var(--DATA_VALUE_BACKGROUND_COLOR);
  font-size:  3.2vh;
  font-size:  var(--DATA_FONT_SIZE);
  height: 11vh;
  height: var(--DATA_BLOCK_HEIGHT);
  color: black;
}

.chartContainer{
  height:70vh;
  width:100%;
  justify-content: center;
  background-color: white;
  -webkit-transform: translate(40px, 0px);
          transform: translate(40px, 0px);

}

.toolTipElement{
  font-family: "Helvetica";
  display: flex;
  flex-direction: column;
  font-size: 2.5vh;
  box-shadow: 0px 5px 15px rgba(0,0,0,0.1);
  background-color: white;
  /* border: 2px solid; */
  border-radius: 5px;
  border-width: thin;
  margin: 2vw;
}

@media (max-width: 767px) {
  :root{
    --LABEL_BLOCK_HEIGHT: 17vh;
    --DATA_BLOCK_HEIGHT: 10vh;
    --BLOCK_WIDTH:100%;
    --DATA_FONT_SIZE:3.2vh;
    --LABEL_FONT_SIZE:2.1vh;
  }
  .videoContainer{
    flex-direction: column;
    overflow: scroll;
  }

  .maintenanceMessageContainer{
    flex-direction: column;
  }

  .statsRow{
    flex-direction: column;
    width:100%;
  }
  .statsBlock{
    width:100%;
    max-width: none;
    margin:0;
    min-width: none;
    margin-bottom: 10px;
  }

  .attributeToGraphSelection{
    flex-direction: column;
  }
  .btn{
    width:100%;
  }

  .chartContainer{
    width:114vw;
  }



}

.vaccineBlock{
  align-items: center;
  border-style: solid;
  border-color: rgb(203, 213, 224);
  border-width: 1px;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.vaccineCallToAction{
  padding-top: 1em;
  font-size: 1.2em;
  text-align: center;
}

.vaccineInfoRow{
  align-items: center;
  justify-content: center;
}

.vaccineSource{
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .vaccineSourceImage{
    height: auto;
    width: 7em;
    padding: 1em;
    border-radius: 1.2em;

  }

  .vaccineSourceImage:hover{
    opacity: 20%;
  }


  /* Vaccine Stats */
  .vaccineStatTitle{
    padding: 0.1em;
    color: gray;
    font-size: 1em;
    width: 10em;
    text-align: center;
  }

  .vaccineStatValue{
    font-weight: bold;
    color: rgb(93,232,196);
    font-size: 2em;
    text-align: center;
  }

  /* Vaccine Graph */

  .vaccination-graph{
    height: 20em;
    width:40vw;
    font-size: 20px;
    display: flex;
    flex-direction: column;
    padding:0.1em
  }

  .vaccination-graph-title{
    padding-top: 1em;
  }

  /* Herd Immunity Bar */
.herd-immunity-block{
  height: 10em;
  display: flex;
  flex-direction: column;
  width: 30vw;
}

.herd-immunity-title{
  font-size: 1.2em;

}

  .herd-immunity-bar{
    width: 12em;
    height: 3em;
    border:gray 0.2em solid;
    border-radius: 1vw;
  }

  .progress{
    height: 100%;
    font-size: 1.2em;
  }

  .current-herd-progress{
    height: 8vh;
    left:0;
    background-color: greenyellow;
    border-radius: 1vw;
    text-align: center;
    justify-content: center;
    align-items: center;
  }

  .herd-immunity-marker{
    position: relative;
    top:-2.6em;
    left:70%;
    width: 0.1vw;
    height: 2.6em;
    border-left:#2ecc71 0.5em solid;

  }

  @media (max-width: 767px) {
    .herd-immunity-title{
      font-size: 1em;
    }

    .herd-immunity-block{
      width:90vw;
    }

    .vaccination-graph{
      width: 90vw;

    }
  
  }

