@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

:root{
  --BLOCK_WIDTH:16vw;
  --DATA_LABEL_BACKGROUND_COLOR: rgb(0, 123, 255);
  --DATA_VALUE_TEXT_COLOR: white;
  --DATA_VALUE_BACKGROUND_COLOR: #ecf0f1;
  --LABEL_BLOCK_HEIGHT: 12vh;
  --DATA_BLOCK_HEIGHT: 11vh;

  --LABEL_FONT_SIZE: 3vh;
  --DATA_FONT_SIZE: 3.2vh;

}

html{
  width:100%;
  height: 100%;
}
body {
  margin: 0;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width:100%;
  height: 100%;

}
#root{
  width:100%;
  height: 100%;
}

.appContainer{
  width:100%;
  height: 100%;
}

.fullSpaceContainer{
  display: flex;
  flex-direction: column;
  width:100%;
  height: 100%;  
}
.flexRow{
  display: flex;
  flex-direction: row;
}

.flexColumn{
  display: flex;
  flex-direction: column;
}

.centeringContainer{
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
}

/* Hackathon Page */

.typingTitle{
  font-size: 80px;
}

.typingAnimationFirst { 
  border-right: solid 3px rgba(0,255,0,.75);
  white-space: nowrap;
  overflow: hidden;    
  font-family: 'Source Code Pro', monospace;  
  color: rgba(255,255,255,.70);
}

.typingAnimationFirst {
  animation: animated-text 4s steps(29,end) 1s 1 normal both,
             animated-cursor 600ms steps(29,end) infinite;
}

.typingAnimationSecond {
  animation-delay: 2s;

  animation: animated-text 4s steps(29,end) 1s 1 normal both,
             animated-cursor 600ms steps(29,end) infinite;
             
}


/* text animation */

@keyframes animated-text{
  from{width: 0%;}
  to{width: 100%;}
}

/* cursor animations */

@keyframes animated-cursor{
  from{border-right-color: rgba(0,255,0,.75);}
  to{border-right-color: transparent;}
}


/* Appear animation */

.fadeInSlow{
  animation: fadeInAnimation ease 10s;
  animation-iteration-count: 1; 
  animation-fill-mode: forwards; 

}

.fadeInFast{
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1; 
  animation-fill-mode: forwards; 

}

@keyframes fadeInAnimation {
  0% {opacity:0;}
  66% { opacity:0; }
  100% {opacity: 1;}
} 




.gradientBackground{
  background: rgb(255,255,255);
  background: linear-gradient(180deg, rgba(255,255,255,1) 66%, rgba(43,238,166,1) 89%, rgba(0,212,255,1) 100%);  
  }

.coolGradient{
  background: rgb(34,193,195);
  background: linear-gradient(0deg, rgba(34,193,195,1) 0%, rgba(253,187,45,1) 100%);  
}

.clickable{
  cursor: pointer;
}

.clickable:hover {
  text-decoration: underline;
}

.videoContainer{
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  width: 100%;
  position: relative;


}

.maintenanceMessageContainer{
  display: flex;
  flex-direction: row;
  align-items:  center;
  height: 100%;
  width: 100%
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.statsRow{
  display: flex;
  flex-direction: row;
  align-items: center;

}

.statsBlock{
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  text-align: center;
  margin: 5px;
  border-style:solid;
  border-width: 1px;
  border-radius: 15px;
  border-color: #cbd5e0;
  display: flex;
  flex-direction: row;
  width: 480px;
  justify-content: space-evenly
}


.attributeToGraphSelection {
  flex-direction:row;
  display:flex;
  padding:10;
  width: 100vw;
  align-items:center;
  justify-content: center;
  margin-top: 2vh;
}

.dashboardBlock {
  width: var(--BLOCK_WIDTH);
  min-width: 85px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content:center;
  text-align: center;
}



.labelBlock{
  background-color: var(--DATA_LABEL_BACKGROUND_COLOR);
  font-size: var(--LABEL_FONT_SIZE);
  height: var(--LABEL_BLOCK_HEIGHT);
  color: var(--DATA_VALUE_TEXT_COLOR);
}

.infoCircle{
  display: none;
  position: absolute;
  top:0;
  right:0;
  transform:scale(0.7);
  cursor: pointer;

}

@keyframes infoCircleHoverAnimation {
  from {fill: white; transform: scale(0.7);}
  to {fill:black;transform: scale(1.1);}
}

.infoCircle:hover{
  animation-name: infoCircleHoverAnimation;
  animation-duration: 1s;
  animation-fill-mode: forwards;

}

.dataBlock{
  background-color: var(--DATA_VALUE_BACKGROUND_COLOR);
  font-size:  var(--DATA_FONT_SIZE);
  height: var(--DATA_BLOCK_HEIGHT);
  color: black;
}

.chartContainer{
  height:70vh;
  width:100%;
  justify-content: center;
  background-color: white;
  transform: translate(40px, 0px);

}

.toolTipElement{
  font-family: "Helvetica";
  display: flex;
  flex-direction: column;
  font-size: 2.5vh;
  box-shadow: 0px 5px 15px rgba(0,0,0,0.1);
  background-color: white;
  /* border: 2px solid; */
  border-radius: 5px;
  border-width: thin;
  margin: 2vw;
}

@media (max-width: 767px) {
  :root{
    --LABEL_BLOCK_HEIGHT: 17vh;
    --DATA_BLOCK_HEIGHT: 10vh;
    --BLOCK_WIDTH:100%;
    --DATA_FONT_SIZE:3.2vh;
    --LABEL_FONT_SIZE:2.1vh;
  }
  .videoContainer{
    flex-direction: column;
    overflow: scroll;
  }

  .maintenanceMessageContainer{
    flex-direction: column;
  }

  .statsRow{
    flex-direction: column;
    width:100%;
  }
  .statsBlock{
    width:100%;
    max-width: none;
    margin:0;
    min-width: none;
    margin-bottom: 10px;
  }

  .attributeToGraphSelection{
    flex-direction: column;
  }
  .btn{
    width:100%;
  }

  .chartContainer{
    width:114vw;
  }



}
